<script setup lang="ts">
import { ref } from "vue";

definePageMeta({
  layout: "homepage",
});

const tocLinks = [
  { id: "start-with-ai" },
  { id: "ai-screening" },
  { id: "contact-form" },
  { id: "consulting" },
  { id: "services" },
  { id: "workflow" },
  { id: "team" },
];

const contentContainer = ref<null | HTMLElement>(null);
const { activeTableOfContentsItemId } = useTableOfContent(contentContainer);
</script>

<template>
  <div class="toc-container" style="z-index: 1">
    <TableOfContent
      :activeTocId="activeTableOfContentsItemId"
      :tocLinks="tocLinks"
      page="saxonyaiHome"
    />
  </div>
  <div
    style="max-width: 100%; overflow-x: hidden"
    ref="contentContainer"
    class=""
  >
    <ContentDoc />
  </div>
</template>
